import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  homeObjOne,
  // homeObjTwo,
  // homeObjThree
} from "../components/InfoSection/Data";

import Portfolio from "../components/Portfolio";
import Pricing from "../components/Pricing";

const PricingPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Navbar toggle={toggle} isHome showSignIn /> */}
      <Navbar toggle={toggle} isHome={false} />
      <Pricing />
      <Footer />
    </>
  );
};

export default PricingPage;
