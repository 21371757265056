import React, { useState, useEffect } from "react";

import { animateScroll as scroll } from "react-scroll";

import { IconContext } from "react-icons/lib";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinkS,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

const Navbar = ({
  toggle,
  isHome,
  showSignIn,
  showSignUp,
  showSignOut,
  signOutHandler,
}) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);

    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "white" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo
              onClick={() => {
                scroll.scrollToTop();
              }}
              to="/"
            >
              CMD
            </NavLogo>
            <NavMenu>
              {isHome ? (
                <>
                  <NavItem>
                    <NavLinkS
                      to="about"
                      smooth={true}
                      duration={500}
                      spy={"true"}
                      exact="true"
                      offset={-80}
                    >
                      About
                    </NavLinkS>
                  </NavItem>
                  <NavItem>
                    <NavLinkS
                      to="services"
                      smooth={true}
                      duration={500}
                      spy={"true"}
                      exact="true"
                      offset={-80}
                    >
                      Services
                    </NavLinkS>
                  </NavItem>
                </>
              ) : null}
            </NavMenu>

            {showSignIn ? (
              <NavBtn>
                <NavBtnLink
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="/signin"
                >
                  Sign In
                </NavBtnLink>
              </NavBtn>
            ) : null}

            {showSignUp ? (
              <NavBtn>
                <NavBtnLink
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="/signup"
                >
                  Sign Up
                </NavBtnLink>
              </NavBtn>
            ) : null}

            {showSignOut ? (
              <NavBtn>
                <NavBtnLink onClick={signOutHandler}>Sign Out</NavBtnLink>
              </NavBtn>
            ) : null}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
