import React from "react";

import ChatV4 from "../components/ChatV4/ChatV4";

const ChatPage = () => {
  return (
    <>
      <ChatV4 />
    </>
  );
};

export default ChatPage;
