import styled from "styled-components";

export const Section = styled.div`
  border: 1px solid black;
  background: #fff;
  height: 50vh;
  width: 98%;
  border-radius: ${(props) => props.theme.margins.asEms[1]};
  margin: ${(props) => props.theme.margins.asEms[1]} auto;
  display: flex;

  .left {
    flex: 1;

    img {
      border-top-left-radius: ${(props) => props.theme.margins.asEms[1]};
      border-bottom-left-radius: ${(props) => props.theme.margins.asEms[1]};
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-right: 1px solid black;
    }
  }

  .right {
    margin: 0 auto;

    padding: ${(props) => props.theme.margins.asEms[1]};
    flex: 1;

    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    .description {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: auto;
      text-align: center;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    border: 1px solid white;
    height: 100%;
    width: 98%;
    border-radius: ${(props) => props.theme.margins.asEms[1]};
    margin: ${(props) => props.theme.margins.asEms[1]} auto;
    display: flex;
    flex-direction: column;

    border-radius: ${(props) => props.theme.margins.asEms[1]};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .left {
      flex: 1;

      img {
        border-top-left-radius: ${(props) => props.theme.margins.asEms[1]};
        border-top-right-radius: ${(props) => props.theme.margins.asEms[1]};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-right: none;
      }
    }

    .right {
      margin: 0 auto;

      padding: ${(props) => props.theme.margins.asEms[1]};
      flex: 1;

      display: flex;
      flex-direction: column;

      overflow-y: auto;

      .description {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: auto;
        text-align: center;
      }
    }
  }
`;

export const SectionAlt = styled.div`
  border: 1px solid black;
  background: #fff;
  height: 50vh;
  width: 98%;
  border-top-right-radius: ${(props) => props.theme.margins.asEms[1]};
  border-bottom-right-radius: ${(props) => props.theme.margins.asEms[1]};
  margin: ${(props) => props.theme.margins.asEms[1]} auto;
  display: flex;

  .left {
    margin: 0 auto;

    padding: ${(props) => props.theme.margins.asEms[1]};
    flex: 1;

    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    .description {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: auto;
      text-align: center;
    }
  }

  .right {
    flex: 1;

    img {
      border-top-right-radius: ${(props) => props.theme.margins.asEms[1]};
      border-bottom-right-radius: ${(props) => props.theme.margins.asEms[1]};
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    border: 1px solid white;
    height: 100%;
    width: 98%;
    border-radius: ${(props) => props.theme.margins.asEms[1]};
    margin: ${(props) => props.theme.margins.asEms[1]} auto;
    display: flex;
    flex-direction: column;

    border-radius: ${(props) => props.theme.margins.asEms[1]};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .left {
      margin: 0 auto;

      border-top-right-radius: 0px;
      border-top-left-radius: 0px;

      padding: ${(props) => props.theme.margins.asEms[1]};
      flex: 1;

      display: flex;
      flex-direction: column;

      overflow-y: auto;

      .description {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: auto;
        text-align: center;
      }
    }

    .right {
      flex: 1;

      img {
        border-radius: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-right: none;
      }
    }
  }
`;
