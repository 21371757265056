import React from "react";

import { FaAppStoreIos, FaAndroid } from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";

import OceanAppPromo from "../../images/Portfolio/OceanAppPromo.png";
import NamesTheAlbumPromo from "../../images/Portfolio/NamesTheAlbum.png";
import RichestManInBabylon from "../../images/Portfolio/RichestManInBabylon.png";
import W_Marketing_Site from "../../images/Portfolio/W_Marketing_Site.png";
import QuaternionDemo from "../../images/Portfolio/QuaternionDemo.png";
import DeckExplorer from "../../images/Portfolio/DeckExplorer.png";
import CommandSite from "../../images/Portfolio/CommandSite.png";
import Kalygo from "../../images/Portfolio/Kalygo.png";
import Emend from "../../images/Portfolio/Emend.png";
import Kalygo2 from "../../images/Portfolio/Kalygo2.png";

import { PortfolioH1, PortfolioContainer } from "./PortfolioElements";

import { Section, SectionAlt } from "../Section";

const Portfolio = () => {
  const tutorials = [
    // {
    //   title: "Emend.ai",
    //   description: "A concept site for text-to-map technology.",
    //   img: Emend,
    //   dimensions: {
    //     x: 1426,
    //     y: 736,
    //   },
    //   webLink: "https://emend.ai",
    // },
    {
      title: "Kalygo 2.0",
      description: "An LLM-powered document processing companion.",
      img: Kalygo2,
      dimensions: {
        x: 1426,
        y: 736,
      },
      webLink: "https://kalygo.io",
    },
    {
      title: "Peer 2 Peer Blockchain Tools",
      description: "An MVP for an open source smart-contract utility app.",
      img: Kalygo,
      dimensions: {
        x: 1426,
        y: 736,
      },
      webLink: "https://kalygo.xyz",
    },
    {
      title: "Summary of 'The Richest Man In Babylon'",
      description: "Age-old financial wisdom.",
      img: RichestManInBabylon,
      dimensions: {
        x: 1566,
        y: 746,
      },
      webLink:
        "http://the-richest-man-in-babylon-summary.com.s3-website-us-east-1.amazonaws.com/",
    },

    {
      title: "Names Album Promo Site",
      description:
        "The promo site for an album made during the COVID shutdowns. Made using React + HTML5 Audio. Hosted on AWS. Music streaming on Spotify, YouTube Music, and Apple Music : )",
      img: NamesTheAlbumPromo,
      dimensions: {
        x: 1825,
        y: 758,
      },
      webLink: "https://namesthealbum.com/",
    },
    // {
    //   title: "Avon Transit",
    //   description:
    //     "A transit app built for a non-emergency transportation company based in Indianapolis, Indiana. The site features integrations with Google Maps, Stripe, Sendgrid, and AWS.",
    //   img: AvonTransitPromo,
    //   dimensions: {
    //     x: 1707,
    //     y: 889,
    //   },
    //   webLink: "https://avontransit.com",
    // },
    {
      title: "Deck Explorer",
      description:
        "A simple Three.js app for exploring sample deck maps for cruise ships.",
      img: DeckExplorer,
      dimensions: {
        x: 1560,
        y: 652,
      },
      webLink:
        "http://cruise-location-tracker.s3-website-us-east-1.amazonaws.com/",
    },
    {
      title: "Quaternion Experiment",
      description:
        "We implemented a primitive rendering engine with WebGL. Features an implementation of Quaternions. ONLY WORKS WITH A KEYBOARD.",
      img: QuaternionDemo,
      dimensions: {
        x: 1684,
        y: 721,
      },
      webLink:
        "http://basic-rendering-engine.s3-website-us-east-1.amazonaws.com/",
    },
    {
      title: "Record Label Concept Site",
      description: "A Three.js concept site made for a record label.",
      img: W_Marketing_Site,
      dimensions: {
        x: 690,
        y: 709,
      },
      webLink: "http://witherberry.com",
    },
    {
      title: "MedallionClass App",
      description: `
          COMMAND was part of the team that delivered a handful of websites and mobile
          apps for the Ocean Medallion project. The Ocean Medallion project was
          a project led by a handful of former Disney executives to integrate
          IoT driven experiences into the Carnival Cruise Line fleet.
      `,
      iosLink: "https://apps.apple.com/us/app/medallionclass/id1475028048",
      androidLink:
        "https://play.google.com/store/apps/details?id=com.carnival.gxi.ocean.compass.medallionclass&hl=en_US&gl=US",
      webLink: "https://www.ocean.com/",
      img: OceanAppPromo,
      dimensions: {
        x: 2264,
        y: 1620,
      },
    },
    {
      title: "COMMAND",
      description: "Very Meta Baby!",
      img: CommandSite,
      dimensions: {
        x: 1825,
        y: 758,
      },
      webLink: "https://cmdlabs.io/",
    },
  ];

  return (
    <PortfolioContainer id="portfolio">
      <PortfolioH1>Portfolio</PortfolioH1>

      {tutorials.map((i, idx) => {
        const isOdd = idx % 2 === 0 ? true : false;

        // console.log("isOdd", isOdd);

        return isOdd ? (
          <Section key={i.title} isOdd={isOdd}>
            <div className={"left"}>
              <img
                alt="Portfolio Preview"
                src={i.img}
                width={i.dimensions && i.dimensions.x}
                height={i.dimensions && i.dimensions.y}
              />
            </div>
            <div className={"right"}>
              <h2 className="title">{i.title}</h2>
              <div className="description">
                {i.description}
                <br />
                <br />
                {i.webLink && (
                  <>
                    <span>
                      web:{" "}
                      <a href={i.webLink} target="_blank" rel="noreferrer">
                        Visit
                      </a>
                    </span>
                  </>
                )}
                {i.iosLink && (
                  <>
                    <span>
                      iOS:{" "}
                      <a href={i.iosLink} target="_blank" rel="noreferrer">
                        Visit
                      </a>
                    </span>
                  </>
                )}
                {i.androidLink && (
                  <>
                    <span>
                      Android:{" "}
                      <a href={i.androidLink} target="_blank" rel="noreferrer">
                        Visit
                      </a>
                    </span>
                  </>
                )}
              </div>
            </div>
          </Section>
        ) : (
          <SectionAlt key={i.title} isOdd={isOdd}>
            <div className={"left"}>
              <h2 className="title">{i.title}</h2>
              <div className="description">
                {i.description}
                <br />
                <br />
                {i.webLink && (
                  <>
                    <span>
                      web:{" "}
                      <a href={i.webLink} target="_blank" rel="noreferrer">
                        Visit
                      </a>
                    </span>
                  </>
                )}
                {i.iosLink && (
                  <>
                    <span>
                      iOS:{" "}
                      <a href={i.iosLink} target="_blank" rel="noreferrer">
                        Visit
                      </a>
                    </span>
                  </>
                )}
                {i.androidLink && (
                  <>
                    <span>
                      Android:{" "}
                      <a href={i.androidLink} target="_blank" rel="noreferrer">
                        Visit
                      </a>
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={"right"}>
              <img
                alt="Portfolio Preview"
                src={i.img}
                width={i.dimensions && i.dimensions.x}
                height={i.dimensions && i.dimensions.y}
              />
            </div>
          </SectionAlt>
        );
      })}
    </PortfolioContainer>
  );
};

export default Portfolio;
