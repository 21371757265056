import React from 'react';
import SignIn from '../components/Forms/SignIn';

import { AltNavbar } from '../components/Navbar'

function SigninPage(props) {

  return (
    <>
      <AltNavbar />
      <SignIn {...props}/>
    </>
  );
}

export default SigninPage;
