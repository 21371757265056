import React from 'react';
import ForgotPassword from '../components/Forms/ForgotPassword';

import { AltNavbar } from '../components/Navbar'

function ForgotPasswordPage() {
  return (
    <>
      <AltNavbar/>
      <ForgotPassword/>
    </>
  );
}

export default ForgotPasswordPage;
