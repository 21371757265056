import React, { useState, useEffect } from "react";
// import Messages from "./Messages";
// import MessageInput from "./MessageInput";

import ChatUI from "./components/ChatUI";
import { io } from "socket.io-client";

// import "./ChatV4.css";

export default function ChatV4() {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    console.log("___ _@@@_ ___", process.env.REACT_APP_CHAT_API_SERVICE_NAME);

    const newSocket = io(process.env.REACT_APP_CHAT_API_SERVICE_NAME, {
      autoConnect: false,
      path: "/socket.io",
    });

    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  return (
    <div className="App">
      {/* <header className="app-header">React Chat</header> */}
      {socket ? (
        <div className="chat-container">
          <ChatUI socket={socket} />
        </div>
      ) : (
        <>NOT CONNECTED</>
      )}
    </div>
  );
}
