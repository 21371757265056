import React, { useEffect } from "react";
import { LeftPanel, RightPanel } from "./ChatUIElements";
import Room from "../Room";
import OnlineMember from "../OnlineMember";
import FeaturedMember from "../FeaturedMember";

import useChat from "../../useChatV4";

import CollectChatGuestInfo from "../../../Forms/CollectChatGuestInfo";
import ChatRoom from "../ChatRoom";
import { Nav } from "../Nav";
import { SideDrawer } from "../SideDrawer";
import { Backdrop } from "../Backdrop";

export default function ChatUI(props) {
  const { socket } = props;

  const {
    chatUIState: {
      rooms,
      selectedChatroom,
      guestInfo,
      featuredChatMembers,
      onlineMembers,
    },
    onSendMessage,
    onEnterChat,
    onJoinRoom,
    onSelectRoom,
    onDeleteRoom,
  } = useChat(socket);

  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    const guestInfo =
      localStorage.getItem("guestInfo") &&
      JSON.parse(localStorage.getItem("guestInfo"));

    if (guestInfo && guestInfo.name && guestInfo.email) {
      onEnterChat(guestInfo);
    } else {
      onEnterChat(null);
    }
  }, []);

  const chatroomData =
    (rooms &&
      rooms.find((u) => {
        return u.roomID === (selectedChatroom && selectedChatroom.roomID);
      })) ||
    null;

  // debugger;

  console.log("selectedChatroom", selectedChatroom);

  return !guestInfo ? (
    <div>
      <CollectChatGuestInfo
        handleSubmitCallback={(formData) => {
          onEnterChat(formData);
        }}
      />
    </div>
  ) : (
    <>
      <Nav onClick={() => setOpen(!open)} onEnterChat={onEnterChat} />
      <SideDrawer
        show={open}
        closeDrawer={() => setOpen(false)}
        onSelectRoom={onSelectRoom}
        onDeleteRoom={onDeleteRoom}
        onJoinRoom={onJoinRoom}
        rooms={rooms}
        featuredChatMembers={featuredChatMembers}
        selectedChatroom={selectedChatroom}
        guestInfo={guestInfo}
        onlineMembers={onlineMembers}
      />
      {open && <Backdrop onClick={() => setOpen(false)} />}

      {/* <div> */}
      {selectedChatroom && chatroomData && (
        <ChatRoom
          selectedChatroom={selectedChatroom}
          chatroomData={chatroomData}
          guestInfo={guestInfo}
          onSendMessage={onSendMessage}
        />
      )}
      {/* </RightPanel> */}
    </>
  );
}
