import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import ChatWidget from "../components/ChatWidget";
import {
  homeObjOne,
  // homeObjTwo,
  homeObjThree,
} from "../components/InfoSection/Data";

import { animateScroll as scroll } from "react-scroll";

import Services from "../components/Services";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    // scroll.scrollToTop();
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Navbar toggle={toggle} isHome showSignIn /> */}
      <Navbar toggle={toggle} isHome />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <Services />
      <InfoSection {...homeObjThree} />
      <Footer />
      {/* <ChatWidget/> */}
    </>
  );
};

export default Home;
