import React from 'react';
import SignUp from '../components/Forms/SignUp';

import { AltNavbar } from '../components/Navbar'

function SignUpPage() {
  return (
    <>
      <AltNavbar/>
      <SignUp />
    </>
  );
}

export default SignUpPage;
