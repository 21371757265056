import React from "react";

import { FaAppStoreIos, FaAndroid } from "react-icons/fa";
import { AiFillAndroid } from "react-icons/ai";

import OceanAppPromo from "../../images/Portfolio/OceanAppPromo.png";
import NamesTheAlbumPromo from "../../images/Portfolio/NamesTheAlbum.png";
import RichestManInBabylon from "../../images/Portfolio/RichestManInBabylon.png";
import W_Marketing_Site from "../../images/Portfolio/W_Marketing_Site.png";
import QuaternionDemo from "../../images/Portfolio/QuaternionDemo.png";
import DeckExplorer from "../../images/Portfolio/DeckExplorer.png";
import CommandSite from "../../images/Portfolio/CommandSite.png";
import Kalygo from "../../images/Portfolio/Kalygo.png";

import { PrivacyH1, PrivacyContainer, PrivacyP } from "./PrivacyElements";

import { Section, SectionAlt } from "../Section";

const Portfolio = () => {
  return (
    <PrivacyContainer id="privacy">
      <PrivacyH1>Privacy Policy</PrivacyH1>
      <PrivacyP>
        COMMAND is looking to test releasing Android apps via FastLane and this
        privacy policy is the privacy policy required by the release form.
      </PrivacyP>
      <PrivacyP>
        This app is a Hello World app (com.command.myapplication) that has no
        features and tracks no application specific data because the app has no
        features.
      </PrivacyP>
    </PrivacyContainer>
  );
};

export default Portfolio;
