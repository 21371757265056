import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#0abc6f" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#ffffff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#ffffff" : "#01BF71")};
  }
`;

// export const ButtonR = styled(LinkR)`
//   border-radius: 50px;
//   background: ${(props) => props.theme.colors.altSecondary};
//   white-space: nowrap;
//   padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
//   color: ${({ dark }) => (dark ? "#fff" : "#ffffff")};
//   font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
//   outline: none;
//   border: none;
//   cursor: pointer;
//   display: flex;
//   margin: 8px;
//   justify-content: center;
//   text-decoration: none;
//   align-items: center;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: ${(props) => props.theme.colors.altPrimary};
//   }
// `;

export const ButtonR = styled(LinkR)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  cursor: pointer;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  background: ${(props) => props.theme.colors.altSecondary};
  margin: 8px;
  border-radius: 50px;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.altTertiary};
    transition: 0.3s ease-out;
    text-decoration: none;
  }
`;
