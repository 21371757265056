import styled from "styled-components";

import { IoMdChatbubbles } from "react-icons/io";

export const ClosedChatWidget = styled.div`
  background: rgba(1, 191, 113, 1);
  padding: 0.8em;
  border-radius: 50px;
  cursor: pointer;
  right: 1rem;
  bottom: 1rem;
  font-size: 2rem;
  position: fixed;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 1);
  z-index: 1;
`;

export const ChatIcon = styled(IoMdChatbubbles)`
  animation-name: bounce;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  position: relative;

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
