import styled from "styled-components";

export const PricingCardSection = styled.div`
  width: 100%;

  .cards_wrap {
    // margin-top: 80px;
    padding: ${(props) => props.theme.margins.asEms[3]};
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .cards_wrap {
    .card_item {
      padding: 0.5em;
      width: calc(100% - 2px);
      margin: 1em;
      background-color: ${(props) => props.theme.colors.altSecondary};

      :hover {
        transform: scale(1.01);
      }
    }

    .card_inner {
      background: ${(props) => props.theme.colors.quaternary};
      border-radius: 5px;
      padding: ${(props) =>
        `${props.theme.paddings.asEms[2]} ${props.theme.paddings.asEms[3]}`};
      width: 100%;
      height: 100%;
    }

    .card_item {
      box-shadow: 1px 1px 2px 1px ${(props) => props.theme.colors.altSecondary};
      border-radius: 5px;

      img {
        border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
        height: auto;
        object-fit: cover;
        // height: max(10rem, 25vh);
        max-height: max(10rem, 30vh);
        aspect-ratio: 4/3;
        // filter: grayscale(100);

        ~ * {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }

      .title {
        color: black;
        font-weight: 400;
        letter-spacing: 2px;
        /* text-transform: uppercase; */
        text-align: center;
        font-size: ${(props) => props.theme.text.fontSizes[5]};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: ${(props) =>
          `${props.theme.margins.asEms[0]} 0 ${props.theme.margins.asEms[0]}`};
      }

      .subtitle {
        text-align: center;
        color: ${(props) => props.theme.text.colors.secondary};
        font-size: ${(props) => props.theme.text.fontSizes[2]};
        font-weight: 500;
      }

      .description {
        font-size: ${(props) => props.theme.text.fontSizes[1]};
        line-height: ${(props) => props.theme.text.fontSizes[3]};
        color: ${(props) => props.theme.text.colors.secondary};
        margin: ${(props) =>
          `${props.theme.margins.asEms[0]} 0 ${props.theme.margins.asEms[1]}`};
        overflow: auto;
        overflow-wrap: anywhere;
      }

      ul {
        text-align: center;
        list-style-type: none;
      }

      .appointment-button {
          background-color: ${(props) => props.theme.colors.altSecondary};
          border-radius: 50px;
          padding: 12px 30px;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          color: white;

          &:hover {
            transition: all 0.2s ease-in-out;
            background: ${(props) => props.theme.colors.altPrimary};
          }
        }
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    .cards_wrap .card_item {
      width: 100%;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    .cards_wrap .card_item {
      width: calc(50% - 2em);
      margin: 1em;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    max-width: 1200px;

    .cards_wrap .card_item {
      width: calc(50% - ${(props) => props.theme.margins.asEms[3]});
      margin: ${(props) => props.theme.margins.asNumbers[3] / 2 + "em"};
    }
  }
`;

export const QRCodesSection = styled.div`
  width: 100%;

  .cards_wrap {
    // margin-top: 80px;
    padding: ${(props) => props.theme.margins.asEms[3]};
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .cards_wrap {
    .card_item {
      padding: 0.5em;
      width: calc(80% - 2px);
      margin: 1em;
      background-color: ${(props) => props.theme.colors.altSecondary};

      :hover {
        transform: scale(1.01);
      }
    }

    .card_inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: ${(props) => props.theme.colors.altSecondary};
      border-radius: 5px;
      padding: ${(props) =>
        `${props.theme.paddings.asEms[2]} ${props.theme.paddings.asEms[3]}`};
      width: 100%;
      height: 100%;
    }

    .card_item {
      box-shadow: 1px 1px 2px 1px ${(props) => props.theme.colors.altPrimary};
      cursor: pointer;
      border-radius: 5px;

      img {
        // border-radius: 0.5rem 0.5rem 0 0;
        width: 80%;
        height: auto;
        // object-fit: cover;
        // height: max(10rem, 25vh);
        // max-height: max(10rem, 30vh);
        // aspect-ratio: 4/3;
        // filter: grayscale(100);

        ~ * {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }

      .title {
        color: black;
        font-weight: 400;
        letter-spacing: 2px;
        /* text-transform: uppercase; */
        text-align: center;
        font-size: ${(props) => props.theme.text.fontSizes[4]};
        white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;
        margin: ${(props) =>
          `${props.theme.margins.asEms[0]} 0 ${props.theme.margins.asEms[0]}`};
      }

      .subtitle {
        text-align: center;
        color: ${(props) => props.theme.text.colors.primary};
        font-size: ${(props) => props.theme.text.fontSizes[2]};
        font-weight: 500;
      }

      .description {
        font-size: ${(props) => props.theme.text.fontSizes[1]};
        line-height: ${(props) => props.theme.text.fontSizes[3]};
        color: ${(props) => props.theme.text.colors.secondary};
        margin: ${(props) =>
          `${props.theme.margins.asEms[0]} 0 ${props.theme.margins.asEms[1]}`};
        overflow: auto;
        overflow-wrap: anywhere;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    .cards_wrap .card_item {
      width: 100%;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    .cards_wrap .card_item {
      width: calc(40% - 2em);
      margin: 1em;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    max-width: 1200px;

    .cards_wrap .card_item {
      width: calc(40% - ${(props) => props.theme.margins.asEms[3]});
      margin: ${(props) => props.theme.margins.asNumbers[3] / 2 + "em"};
    }
  }
`;
