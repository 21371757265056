import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Home from "./pages";
import DashboardPage from "./pages/dashboard";
import SigninPage from "./pages/signin";
import SignUpPage from "./pages/signup";
import ContactPage from "./pages/contact";
import PortfolioPage from "./pages/portfolio";
import PricingPage from "./pages/pricing";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import PrivacyPage from "./pages/privacy";
import Chat from "./pages/chat";

import { ProvideAuth, useAuth } from "./providers/authProvider";
import axios from "axios";

import loadingIcon from "../src/images/loader.svg";
import pkg from "../package.json";

import { theme as chinaTheme } from "./styles/themes/main";

function PrivateRoute({ children, ...rest }) {
  let { authContext } = rest;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const isAuthed =
          authContext.isAuthedModel && authContext.isAuthedModel.isAuthed;

        if (isAuthed) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
}

function App() {
  let authContext = useAuth();

  useEffect(() => {
    async function checkAuthStatus() {
      try {
        authContext.updateIsAuthedModel({
          isAuthedLoading: true,
        });

        await axios.get(
          `${process.env.REACT_APP_AUTH_API_SERVICE_NAME}/isAuthed`,
          { withCredentials: true }
        );

        authContext.updateIsAuthedModel({
          isAuthedLoading: false,
          isAuthedError: null,
          isAuthed: true,
        });
      } catch (e) {
        authContext.updateIsAuthedModel({
          isAuthedLoading: false,
          isAuthedError: e,
          isAuthed: false,
        });
      }
    }

    checkAuthStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={chinaTheme}>
        <ProvideAuth>
          {authContext.isAuthedModel &&
          authContext.isAuthedModel.isAuthedLoading ? (
            <img
              src={loadingIcon}
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              alt="loader"
            />
          ) : (
            <Router>
              <Switch>
                <Route path="/" component={Home} exact />
                <Route
                  path="/signin"
                  render={(routeProps) => {
                    return (
                      <SigninPage authContext={authContext} {...routeProps} />
                    );
                  }}
                  exact
                />
                <Route
                  path="/version"
                  render={(routeProps) => {
                    return <div>{pkg.version}</div>;
                  }}
                  exact
                />
                <Route path="/chat" component={Chat} exact />
                <Route path="/signup" component={SignUpPage} exact />
                <Route path="/contact" component={ContactPage} exact />
                <Route path="/portfolio" component={PortfolioPage} exact />
                <Route path="/pricing" component={PricingPage} exact />
                <Route path="/privacy" component={PrivacyPage} exact />

                <Route
                  path="/forgotPassword"
                  component={ForgotPassword}
                  exact
                />
                <Route
                  path="/resetPassword/:resetPasswordToken"
                  component={ResetPassword}
                  exact
                />
                <PrivateRoute authContext={authContext} path="/dashboard" exact>
                  <DashboardPage />
                </PrivateRoute>
                <Route
                  path="/"
                  render={(routeProps) => {
                    return <>404</>;
                  }}
                />
              </Switch>
            </Router>
          )}

          <ToastContainer />
        </ProvideAuth>
      </ThemeProvider>
    </>
  );
}

export default App;
