import React, { useContext, createContext, useState } from "react";

// import Cookies from 'universal-cookie';

// import axios from 'axios';
// import { toast } from "react-toastify";

const authContext = createContext();

export function useProvideAuth() {
    
    const [isAuthedModel, setIsAuthedModel] = useState({
        isAuthed: false,
        isAuthedLoading: true,
        isAuthedError: null
    })

    const updateIsAuthedModel = async (updates) => {
        setIsAuthedModel({
            ...isAuthedModel,
            ...updates
        })
    }
  
    return {
        isAuthedModel,
        setIsAuthedModel,
        updateIsAuthedModel
    }
}

export function useAuth() {
    return useContext(authContext);
}

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}
