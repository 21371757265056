import React, { useState } from "react";

import {
  Container,
  WrapForm,
  Limiter,
  Form,
  FormTitle,
  WrapFormField,
  FormFieldLabel,
  Input,
  ContainerSubmitBtn,
  SubmitBtn,
  FooterLink,
  FooterLinksContainer,
} from "../FormElements";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { animateScroll as scroll } from "react-scroll";

import { toast } from "react-toastify";

import axios from "axios";

import loadingIcon from "../../../images/loader.svg";

const SignUp = () => {
  const history = useHistory();

  const [signUpState, setSignUpState] = useState({
    signUp: false,
    signUpError: null,
    signUpLoading: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const onSubmit = async (data) => {
    try {
      setSignUpState({
        ...signUpState,
        signUp: false,
        signUpError: null,
        signUpLoading: true,
      });

      await axios.post(
        `${process.env.REACT_APP_AUTH_API_SERVICE_NAME}/signup`,
        {
          name: data.name,
          email: data.email,
          password: data.password,
        }
      );

      setSignUpState({
        ...signUpState,
        signUp: true,
        signUpError: null,
        signUpLoading: false,
      });

      history.push("/signin");

      toast.success("🦄 Wow so easy!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e) {
      setSignUpState({
        ...signUpState,
        signUp: false,
        signUpError: e,
        signUpLoading: false,
      });

      toast.error(`${e.toString()}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  watch("email");
  watch("password");

  return (
    <>
      <Limiter>
        <Container>
          <WrapForm>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <IoArrowBack
                onClick={() => {
                  toggleHome();
                  history.goBack();
                }}
              />

              <FormTitle>Sign Up</FormTitle>

              <div>
                <FormFieldLabel htmlFor="name">Name</FormFieldLabel>

                <WrapFormField
                  isDirty={dirtyFields.name}
                  hasError={!!errors.name}
                >
                  <Input
                    placeholder="Name"
                    name="name"
                    {...register("name", {
                      required: true,
                    })}
                  />
                </WrapFormField>

                {errors.name ? (
                  <span style={{ color: "red" }}>Name Required</span>
                ) : (
                  <br />
                )}
              </div>

              <div>
                <FormFieldLabel htmlFor="email">Email</FormFieldLabel>

                <WrapFormField
                  isDirty={dirtyFields.email}
                  hasError={!!errors.email}
                >
                  <Input
                    placeholder="Email"
                    name="email"
                    type="email"
                    {...register("email", {
                      required: true,
                      // eslint-disable-next-line no-useless-escape
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                    })}
                  />
                </WrapFormField>

                {errors.email ? (
                  <span style={{ color: "red" }}>Email Required</span>
                ) : (
                  <br />
                )}
              </div>

              <div>
                <FormFieldLabel htmlFor="password">Password</FormFieldLabel>

                <WrapFormField
                  isDirty={dirtyFields.password}
                  hasError={!!errors.password}
                >
                  <Input
                    placeholder="Password"
                    name="password"
                    type="password"
                    {...register("password", { required: true })}
                  />
                </WrapFormField>

                {errors.password ? (
                  <span style={{ color: "red" }}>Required</span>
                ) : (
                  <br />
                )}
              </div>

              <ContainerSubmitBtn>
                <SubmitBtn
                  disabled={!isValid || signUpState.signUpLoading}
                  isValid={isValid}
                >
                  {signUpState.signUpLoading ? (
                    <img
                      style={{
                        opacity: "0.5",
                        height: "60px",
                      }}
                      src={loadingIcon}
                      alt="loader"
                    />
                  ) : (
                    <>Sign Up</>
                  )}
                </SubmitBtn>
              </ContainerSubmitBtn>

              <FooterLinksContainer>
                <FooterLink
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="/signin"
                >
                  Already have an account?
                </FooterLink>
              </FooterLinksContainer>
            </Form>
          </WrapForm>
        </Container>
      </Limiter>
    </>
  );
};

export default SignUp;
