import React, { useEffect, useState } from "react";
import axios from "axios";

function ForgotPasswordPage() {
  const [state, setState] = useState({
    val: [],
    error: null,
    loading: true,
  });

  useEffect(() => {
    async function loadFeaturedChatMembers() {
      try {
        // const res = await axios.get(
        //   `${process.env.REACT_APP_DATA_API_SERVICE_NAME}/data/featuredChatMembers`,
        //   { withCredentials: true }
        // );

        setState((val) => {
          return {
            ...val,
            val: [
              {
                name: "Tad",
                email: "ceemmmdee@gmail.com",
                phone: "305-336-6417",
                skills: [
                  "Business Development",
                  "React Native",
                  "Mobile Development",
                ],
              },
            ],
          };
        });
      } catch (e) {
        console.error(e);
      }
    }

    loadFeaturedChatMembers();
  }, []);

  return (
    <div style={{ padding: "1em" }}>
      {state.val && !state.error ? (
        state.val.map((i, idx) => {
          return (
            <div key={i.email}>
              <h3>Contact Us</h3>
              <h5>{i.email}</h5>
              <h5>{i.phone}</h5>
              {/* <h6>Skills: {i.skills.join(", ")}</h6> */}
              <hr />
            </div>
          );
        })
      ) : (
        <h3>Try again later</h3>
      )}
    </div>
  );
}

export default ForgotPasswordPage;
