import React from 'react';
import ResetPassword from '../components/Forms/ResetPassword';

import { AltNavbar } from '../components/Navbar'

function ResetPasswordPage(props) {
  return (
    <>
      <AltNavbar/>
      <ResetPassword {...props}/>
    </>
  );
}

export default ResetPasswordPage;
