import { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import objectHash from "object-hash";

import { ToastHelper } from "../../helpers/toast";
import events from "./events";

const useChat = (socket) => {
  console.log("*!*!* useChat *!*!*");

  const [chatUIState, setChatUIState] = useState({
    guestInfo: null,
    selectedChatroom: {},
    onlineMembers: [],
    rooms: [],
    featuredChatMembers: [],
  });

  useEffect(() => {
    async function loadFeaturedChatMembers() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DATA_API_SERVICE_NAME}/featuredChatMembers`,
          { withCredentials: true }
        );

        setChatUIState((val) => {
          return {
            ...val,
            featuredChatMembers: res.data,
          };
        });
      } catch (e) {
        console.error(e);
      }
    }

    loadFeaturedChatMembers();
  }, []);

  useEffect(() => {
    events.session(socket);
    events.connectError(socket, onEnterChat, ToastHelper);
    events.connect(socket, ToastHelper);
    events.disconnect(socket, ToastHelper, setChatUIState);
    events.rooms(socket, ToastHelper, setChatUIState);

    events.sessionConnected(socket, ToastHelper, setChatUIState);
    events.sessionDisconnected(socket, ToastHelper, setChatUIState);

    events.onlineMembers(socket, ToastHelper, setChatUIState);
    events.privateMessage(socket, ToastHelper, setChatUIState, cloneDeep);

    events.roomConnected(socket, ToastHelper, setChatUIState);
    events.roomDisconnected(socket, ToastHelper, setChatUIState);

    events.kaka1(socket);
    events.kaka2(socket);

    return () => {
      socket.off("session");
      socket.off("connect_error");
      socket.off("connect");
      socket.off("disconnect");
      socket.off("rooms");
      socket.off("online members");

      socket.off("room connected");
      socket.off("room disconnected");

      socket.off("session connected");
      socket.off("session disconnected");
      socket.off("private message");
    };
  }, [socket]);

  const onSelectRoom = (room) => {
    console.log("onJoinRoom", room);

    setChatUIState((val) => {
      return {
        ...val,
        selectedChatroom: room,
      };
    });
  };

  const onJoinRoom = (roomMembers) => {
    let membersEmailForHash = [];
    let membersInfoForBuildingName = [];
    let roomName = "";
    for (let i = 0; i < roomMembers.length; i++) {
      membersEmailForHash.push(roomMembers[i].email);
      membersInfoForBuildingName.push(roomMembers[i]);
    }

    membersEmailForHash.sort();
    membersInfoForBuildingName.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    for (let i = 0; i < membersInfoForBuildingName.length; i++) {
      roomName +=
        membersInfoForBuildingName[i].name +
        (i !== membersInfoForBuildingName.length - 1 ? ", " : "");
    }

    let roomID = objectHash(membersEmailForHash).substring(0, 10);

    const roomInfo = {
      members: roomMembers,
      roomID,
      name: roomName,
      messages: [],
    };

    console.log("roomInfo", roomInfo);

    // if room not already registered
    if (
      !chatUIState.rooms.find((r) => {
        // debugger;
        return r.roomID === roomInfo.roomID;
      })
    ) {
      setChatUIState((val) => {
        let roomsCopy = [...val.rooms];

        return {
          ...val,
          rooms: [...roomsCopy, roomInfo],
        };
      });

      socket.emit("join room", roomInfo);
    } else {
      ToastHelper.error(
        "Room appears to already exist. Refresh and try again if your issue persists."
      );
    }
  };

  const onDeleteRoom = (roomToDelete) => {
    console.log("onDeleteRoom", roomToDelete);

    socket.emit("delete room", roomToDelete);

    setChatUIState((val) => {
      let roomsCopy = [...val.rooms];

      for (let i = 0; i < roomsCopy.length; i++) {
        let room = roomsCopy[i];

        if (room.roomID === (roomToDelete && roomToDelete.roomID)) {
          roomsCopy = [...roomsCopy.slice(0, i), ...roomsCopy.slice(i + 1)];
          break;
        }
      }

      return {
        ...val,
        rooms: [...roomsCopy],
        selectedChatroom: {
          guestInfo: chatUIState.guestInfo || {},
          hasNewMessages: false,
          hasNewMessagesAlert: false,
          members: [chatUIState.guestInfo || {}],
          messages: [],
          name: (chatUIState.guestInfo && chatUIState.guestInfo.name) || "",
          roomID:
            (chatUIState.guestInfo &&
              chatUIState.guestInfo.email &&
              objectHash([chatUIState.guestInfo.email]).substring(0, 10)) ||
            "",
          self: true,
        },
      };
    });
  };

  const onSendMessage = (messageBody, selectedChatroom) => {
    console.log("onSendMessage");

    socket.emit("private message", {
      content: messageBody,
      fromSessionID: objectHash([chatUIState.guestInfo.email]).substring(0, 10),
      fromGuestInfo: chatUIState.guestInfo,
      roomID: selectedChatroom.roomID,
    });

    setChatUIState((val) => {
      if (val.selectedChatroom) {
        let roomsCopy = [...val.rooms];

        for (let i = 0; i < roomsCopy.length; i++) {
          let room = roomsCopy[i];

          if (
            room.roomID ===
            (val.selectedChatroom && val.selectedChatroom.roomID)
          ) {
            room = {
              ...room,
              messages: [
                ...room.messages,
                {
                  content: messageBody,
                  fromSelf: true,
                  fromGuestInfo: val.guestInfo,
                },
              ],
              hasNewMessages: true,
            };

            roomsCopy = [
              ...roomsCopy.slice(0, i),
              room,
              ...roomsCopy.slice(i + 1),
            ];

            break;
          }
        }

        return {
          ...val,
          rooms: [...roomsCopy],
        };
      } else {
        return {
          ...val,
        };
      }
    });
  };

  const onEnterChat = (guestInfo) => {
    if (guestInfo) {
      const { email, name } = guestInfo;

      // hash the user with a single item array to produce
      // a reproducible sessionID that can be used to retrieve the
      // state of the chat experience from a hash map in redis
      const sessionID = objectHash([email]).substring(0, 10);

      setChatUIState((val) => {
        return {
          ...val,
          guestInfo: {
            email,
            name,
          },
          selectedChatroom: {
            guestInfo: guestInfo || {},
            hasNewMessages: false,
            hasNewMessagesAlert: false,
            members: [guestInfo || {}],
            messages: [],
            name: (guestInfo && guestInfo.name) || "",
            roomID:
              (guestInfo &&
                guestInfo.email &&
                objectHash([guestInfo.email]).substring(0, 10)) ||
              "",
            self: true,
          },
        };
      });

      if (email && name) {
        socket.auth = {
          guestInfo,
          sessionID,
        };
        socket.connect();
      }
    } else {
      setChatUIState((val) => {
        return {
          ...val,
          guestInfo: null,
        };
      });

      localStorage.removeItem("guestInfo");

      socket.disconnect();
    }
  };

  return {
    chatUIState,
    onSendMessage,
    onEnterChat,
    onSelectRoom,
    onJoinRoom,
    onDeleteRoom,
  };
};

export default useChat;
