import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ProvideAuth } from "./providers/authProvider";

// import { createStore } from "redux";

// import rootReducer from "./store/reducers";

// import { configureStore } from "@reduxjs/toolkit";

// export default configureStore({
//   reducer: {},
// })

// const store = createStore(rootReducer);
// const store = configureStore({
//   reducer: rootReducer,
// });

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    <ProvideAuth>
      <App />
    </ProvideAuth>
    {/* </Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
