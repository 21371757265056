import React, { useState, useEffect } from "react";

import { animateScroll as scroll } from "react-scroll";

import { IconContext } from "react-icons/lib";

import {
  Nav,
  AltNav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinkS,
  NavLinkR,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

import { FaBars } from "react-icons/fa";

const Navbar = ({ toggle, isHome, showSignIn, showSignUp }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);

    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "white" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo
              onClick={() => {
                scroll.scrollToTop();
              }}
              to="/"
            >
              CMD
            </NavLogo>
            <MobileIcon onClick={toggle} id="test">
              <FaBars />
            </MobileIcon>
            <NavMenu>
              {/* {isHome ? (
                <>
                  <NavItem>
                    <NavLinkS
                      to="about"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      About
                    </NavLinkS>
                  </NavItem>
                  <NavItem>
                    <NavLinkS
                      to="services"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Services
                    </NavLinkS>
                  </NavItem>
                </>
              ) : null} */}
              <NavItem>
                <NavLinkR
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="portfolio"
                  exact="true"
                >
                  Portfolio
                </NavLinkR>
              </NavItem>
              <NavItem>
                <NavLinkR
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="pricing"
                  exact="true"
                >
                  Pricing
                </NavLinkR>
              </NavItem>
            </NavMenu>

            {showSignIn ? (
              <NavBtn>
                <NavBtnLink
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="/signin"
                >
                  Log In
                </NavBtnLink>
              </NavBtn>
            ) : null}

            {showSignUp ? (
              <NavBtn>
                <NavBtnLink
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to="/signup"
                >
                  Sign Up
                </NavBtnLink>
              </NavBtn>
            ) : null}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export const AltNavbar = ({ toggle, isHome, showSignIn, showSignUp }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);

    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "white" }}>
        <AltNav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo
              onClick={() => {
                scroll.scrollToTop();
              }}
              to="/"
            >
              CMD
            </NavLogo>
          </NavbarContainer>
        </AltNav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
