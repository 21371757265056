import React from "react";

import { Limiter, Container } from "./DashboardElements";

import axios from "axios";
import { useHistory } from "react-router";
import Navbar from "./Navbar";
import QRCode from "qrcode.react";
import { ToastHelper } from "../../helpers/toast";

const Dashboard = () => {
  const history = useHistory();

  const signOut = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_AUTH_API_SERVICE_NAME}/signOut`,
        { withCredentials: true }
      );

      history.push("/");

      ToastHelper.success("🦄 You have been signed out!");
    } catch (e) {
      ToastHelper.error(`${e.toString()}`);
    }
  };

  return (
    <>
      <Limiter>
        <Navbar
          showSignOut
          signOutHandler={() => {
            signOut();
          }}
        />

        <Container>
          BTC
          <QRCode value="3Azg7EfQbodvAJU6JGqu3KMYJz3rUgAbas" />
        </Container>
      </Limiter>
    </>
  );
};

export default Dashboard;
