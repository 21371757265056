import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import {
  homeObjOne,
  // homeObjTwo,
  // homeObjThree
} from "../components/InfoSection/Data";

import Portfolio from "../components/Portfolio";
import Privacy from "../components/Privacy";

const PrivacyPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Navbar toggle={toggle} isHome showSignIn /> */}
      <Navbar toggle={toggle} isHome={false} />
      {/* <HeroSection /> */}
      {/* <InfoSection {...homeObjOne} /> */}
      {/* <Portfolio /> */}
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPage;
