import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinkPDF,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              {/* <FooterLinkTitle>DOCUMENTS</FooterLinkTitle> */}
              <FooterLinkPDF
                onClick={() => {
                  window.location.href = process.env.REACT_APP_SKILLS_URL;
                }}
              >
                Skills
              </FooterLinkPDF>
              <FooterLinkPDF
                onClick={() => {
                  window.location.href = process.env.REACT_APP_AOI_URL;
                }}
              >
                Articles of Incorporation
              </FooterLinkPDF>
              {/* <FooterLinkPDF
                onClick={() => {
                  window.location.href = process.env.REACT_APP_COI_URL;
                }}
              >
                Certificate of Insurance
              </FooterLinkPDF> */}
            </FooterLinkItems>
            {/* <FooterLinkItems> */}
            {/* <FooterLinkTitle>CONTACT</FooterLinkTitle> */}
            {/* <FooterLink to="/chat">Live Chat</FooterLink> */}
            {/* <FooterLink to="/contact">Email</FooterLink> */}
            {/* </FooterLinkItems> */}
          </FooterLinksWrapper>
          {/* <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Videos</FooterLinkTitle>
              <FooterLink to="/">Submit Video</FooterLink>
              <FooterLink to="/">Ambassadors</FooterLink>
              <FooterLink to="/">Agency</FooterLink>
              <FooterLink to="/">Influencer</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink to="/">Instagram</FooterLink>
              <FooterLink to="/">Facebook</FooterLink>
              <FooterLink to="/">Youtube</FooterLink>
              <FooterLink to="/">Twitter</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper> */}
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              CMD
            </SocialLogo>
            {/* <WebsiteRights>© {new Date().getFullYear()}</WebsiteRights> */}
            <SocialIcons>
              {/* <SocialIconLink
                href="https://www.facebook.com/pansmusic"
                target="_blank"
                aria-label="Facebook"
                rel="nofollow"
              >
                <FaFacebook />
              </SocialIconLink> */}
              {/* <SocialIconLink
                href="https://www.instagram.com/witherberry/"
                target="_blank"
                aria-label="Instagram"
                rel="nofollow"
              >
                <FaInstagram />
              </SocialIconLink> */}
              <SocialIconLink
                href="https://www.youtube.com/channel/UCO_BAgHvRm7j-8Nz2GwYCVA/videos"
                target="_blank"
                aria-label="Youtube"
                rel="nofollow"
              >
                <FaYoutube />
              </SocialIconLink>
              &nbsp;
              <SocialIconLink
                href="https://www.linkedin.com/company/cmd-labs"
                target="_blank"
                aria-label="Linkedin"
                rel="nofollow"
              >
                <FaLinkedin />
              </SocialIconLink>
              &nbsp;
              <SocialIconLink
                target="_blank"
                aria-label="Twitter"
                href="https://twitter.com/cmd_labs"
                rel="nofollow"
              >
                <FaTwitter />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
