import styled from 'styled-components';

export const Limiter = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    108deg,
    rgba(1, 147, 86, 1) 0%,
    rgba(10, 201, 122, 1) 100%
  );
`;